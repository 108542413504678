import React, { FunctionComponent } from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import Pill from "../../components/Pill"
import Container from "../../components/Container"
import { graphql, Link } from "gatsby"
import ActionPanel from "../../components/home/ActionPanel"
import SignUpForm from "../../components/SignUpForm"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { useQueryParam } from 'gatsby-query-params'
import styles from './index.module.css'


interface QueryResult {
  mdx: {
    frontmatter: {
      title: string
      seo: {
        title: string
        description: string
      }
    }
    body: string
  }
}

interface Props {
  data: QueryResult
}

const PrivacyPage: FunctionComponent<Props> = ({ data }) => {
  const page = data.mdx
  const disablePopovers = useQueryParam('ifr', undefined)
  return (
    <Layout>
      <Seo
        description={page.frontmatter.seo.description}
        pathname="/privacy"
        title={page.frontmatter.seo.title}
      />

      <header id='br-privacy-header'>
        <Pill bgClass="bg-green-100" variant="center-right">
          <Container>
            <div className="py-24 xxl:py-32">
              <div className="br-col-gap md:grid md:grid-cols-12">
                <div className="md:col-span-10 md:col-start-2 xxl:col-span-8 xxl:col-start-3">
                  <div className="br-prose ">
                    <h1 className="mb-8 text-center">{page.frontmatter.title}</h1>
                  </div>

                  <ul className="flex list-none gap-1 justify-center">
                    <li className="list-none"><a className="inline-block bg-black text-white rounded-full px-6 py-2" style={{textDecoration: 'none'}}>Customer, User, Guest</a></li>
                    <li className="list-none"><Link to={`/privacy-entity${!!disablePopovers ? '?ifr=true' : ''}`} className="inline-block bg-white rounded-full px-6 py-2" style={{textDecoration: 'none'}}>Business entity</Link></li>
                  </ul>

                </div>
              </div>
            </div>
          </Container>
        </Pill>
      </header>
      <section className={`pt-24 ${styles.root}`}>
        <Container>
          <div className="br-col-gap md:grid md:grid-cols-12">
            <div className="md:col-span-10 md:col-start-2 xxl:col-span-8 xxl:col-start-3">
              <div className="br-prose list-counter">
                <MDXRenderer>{page.body}</MDXRenderer>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <div id="br-join" className="pt-48 pb-56">
        <ActionPanel>
          <h2 className="mb-6 text-4 md:mb-8 md:text-8">
            Join Breezy
          </h2>

          <p className="mb-10 md:mb-16">
            Say goodbye to your realms of spreadsheets and make Breezy the place you organise, filter and sort your
            partner prospects 10x more efficiently. What are you waiting for?
          </p>

          <div className="max-w-xl mx-auto">
            <SignUpForm/>
          </div>
        </ActionPanel>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query privacy {
    mdx(fileAbsolutePath: { regex: "//static/privacy-customer-user-guest-index/" }) {
      frontmatter {
        title
        seo {
          description
          title
        }
      }
      body
    }
  }
`

export default PrivacyPage
